import React from "react"
import { Link } from "gatsby"
import Layout from "../components/layout"
import { emailadres } from "../utility/strings"

export default function Home() {
  return (
    <Layout>
      <div className="full-text-button">
        <div className="full-text-button_text full-text-button_text--top has-margin-bottom-medium">
          <div>
            <p>
              Laatste update:{" "}
              <span className="has-text-weight-bold">03 juni 2021</span>
            </p>
            <br></br>
            <h2 className="has-no-margin">De Bibliotheek in 2025</h2>
            <h5 className="blue-text">
              Visie op bibliotheekdiensten van de toekomst
            </h5>
            <p>
              Welkom bij de Bibliotheek in 2025. Deze site toont de visie van
              BiSC en Cubiss op diensten die de bibliotheek zou kunnen leveren
              in de toekomst. Via de volgende stappen nemen we je mee:{" "}
            </p>
            <ol className="list list--has-large-numbers">
              <li>
                <span>
                  Krantenkoppen schetsen de veranderende wereld en
                  maatschappelijke trends waarin de bibliotheek een prominente
                  rol wil blijven spelen.
                </span>
              </li>
              <li>
                <span>
                  De missie en doelstellingen laten zien waar we als bibliotheek
                  voor willen staan.
                </span>
              </li>
              <li>
                <span>
                  Je ziet vervolgens de doelstellingen met bijpassende verhalen.
                </span>
                <ul className="list has-margin-top-tiny">
                  <li>
                    Blader door de doelstellingen en lees waar we nu staan en
                    waar we naartoe willen als bibliotheek.
                  </li>
                  <li>
                    Klik op de personages bij iedere doelstelling en ontdek hun
                    verhalen met de bieb. Zo ontdek je hoe de bibliotheek in
                    2025 ervaren kan worden.
                  </li>
                </ul>
              </li>
              <li>
                <span>
                  Tot slot nodigen we je uit om jouw beeld met ons te delen via
                  “de bieb van ons allemaal”. Hier zullen we ook de verhalen van
                  anderen delen.
                </span>
              </li>
            </ol>
            <p>
              Veel plezier met het ontdekken van de Bibliotheek in 2025. Heb je
              vragen of ideeën, mail dan naar:{" "}
              <a
                href={`mailto:${emailadres}`}
                rel="noopener noreferrer"
                target="_blank"
              >
                {emailadres}
              </a>
            </p>
          </div>
        </div>
        <div className="full-text-button_button">
          <Link className="button" to="/nieuws2025/">
            Ga met ons mee richting 2025
          </Link>
        </div>
      </div>
    </Layout>
  )
}
